import React, { useEffect, useState } from "react";
import { Table, Spinner, Alert, Form } from "react-bootstrap";
import { url } from "../../utils/networkconfig";

const AdminEmailLogs = ({ activeTab }) => {
  const [emailLogs, setEmailLogs] = useState([]);
  const [filteredLogs, setFilteredLogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filter, setFilter] = useState("All");

  useEffect(() => {
    if (activeTab === "emailLogs") {
      setLoading(true);
      setError(null);

      fetch(`${url}/api/get-email-logs/`)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to fetch email logs.");
          }
          return response.json();
        })
        .then((data) => {
          setEmailLogs(data);
          setFilteredLogs(data);
          setLoading(false);
        })
        .catch((err) => {
          setError(err.message);
          setLoading(false);
        });
    }
  }, [activeTab]); // Dependency added to re-fetch on tab change

  // Handle filter change
  const handleFilterChange = (e) => {
    const selectedFilter = e.target.value;
    setFilter(selectedFilter);

    if (selectedFilter === "All") {
      setFilteredLogs(emailLogs);
    } else {
      setFilteredLogs(emailLogs.filter((log) => log.status === selectedFilter));
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-xl font-semibold text-gray-700 mb-4">Email Logs</h2>

      {/* Filter Dropdown */}
      <Form.Select
        value={filter}
        onChange={handleFilterChange}
        className="mb-3 w-48"
      >
        <option value="All">All</option>
        <option value="Accepted">Accepted</option>
        <option value="Sent">Sent</option>
      </Form.Select>

      {/* Loading Spinner */}
      {loading && (
        <div className="flex justify-center">
          <Spinner animation="border" variant="primary" />
        </div>
      )}

      {/* Error Message */}
      {error && <Alert variant="danger">{error}</Alert>}

      {/* No Records Found Message */}
      {!loading && !error && filteredLogs.length === 0 && (
        <Alert variant="warning">No email logs found.</Alert>
      )}

      {/* Email Logs Table */}
      {!loading && !error && filteredLogs.length > 0 && (
        <Table striped bordered responsive className="mt-3 admin-table">
          <thead className="bg-gray-200">
            <tr>
              <th>ID</th>
              <th>Email To</th>
              <th>Email By</th>
              <th>Sent At</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {filteredLogs.map((log) => (
              <tr key={log.id}>
                <td>{log.id}</td>
                <td>{log.email_to}</td>
                <td>{log.email_by}</td>
                <td>{new Date(log.sent_at).toLocaleString()}</td>
                <td>
                  <span
                    className={`px-2 py-1 rounded text-white ${
                      log.status === "Sent"
                        ? "bg-blue-400"
                        : log.status === "Accepted"
                        ? "bg-green-400"
                        : "bg-gray-400"
                    }`}
                  >
                    {log.status}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </div>
  );
};

export default AdminEmailLogs;
