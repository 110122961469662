import React, { useState } from 'react';
import { Modal, Button, Row, Col } from 'react-bootstrap'; // Assuming you're using react-bootstrap for Modal
import TickIcon from '../../assets/charm_circle-tick.svg';
import { url } from '../../utils/networkconfig';
import Cookies from 'js-cookie';


const InviteClientModal = ({ show, handleClose }) => {
    const [clientName, setClientName] = useState('');
    const [email, setEmail] = useState('');
    const [invitationMessage, setInvitationMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const user = Cookies.get('admin') ? JSON.parse(Cookies.get('admin')) : null;
    

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const bodyData = {
            clientName,
            email,
            message: invitationMessage || 'Welcome',
            adminName: user?.admin_name
        };

        try {
            // Send POST request to the API
            const response = await fetch(`${url}/api/send-invitation/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(bodyData),
            });

            // Simulate API call
            const result = await response.json();

            if (response.ok) {
                setLoading(false);
                setSuccess(true);

                // Close the modal after 2 seconds
                setTimeout(() => {
                    handleClose();
                    setSuccess(false);
                    setClientName('');
                    setEmail('');
                    setInvitationMessage('');
                }, 2000);
            } else {
                // Handle error case
                console.error('Failed to send invitation:', result);
                setLoading(false);
            }
        } catch (error) {
            console.error('Error:', error);
            setLoading(false);
        }
    };


    return (
        <Modal show={show} onHide={handleClose} dialogClassName="modal-lg">
            <Modal.Header closeButton>
                <Modal.Title className='!text-[#04394F] !font-bold'>{!success ? "Invite New Client" : "Invitation Sent"}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="max-h-[600px]">
                {!success ? (
                    <form onSubmit={handleSubmit}>
                        <Row>
                            <Col className="mb-3">
                                <label className="form-label">Client Name*</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={clientName}
                                    onChange={(e) => setClientName(e.target.value)}
                                    required
                                />
                            </Col>
                            <Col className="mb-3">
                                <label className="form-label">Email*</label>
                                <input
                                    type="email"
                                    className="form-control"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                />
                            </Col>
                        </Row>
                        <div className="mb-3">
                            <label className="form-label">Invitation Message</label>
                            <textarea
                                className="form-control h-32" // Increased height for textarea
                                value={invitationMessage}
                                onChange={(e) => setInvitationMessage(e.target.value)}
                            />
                        </div>
                        <div className='flex'>
                            <Button type="submit" disabled={loading || !clientName || !email}
                                className="btn btn-primary w-full">
                                {loading ? (
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                ) : (
                                    'Send Invite'
                                )}
                            </Button>
                        </div>
                    </form>
                ) : (
                    <div className="text-center flex justify-center items-center align-middle">
                        <img src={TickIcon} alt='TickIcon'></img>
                    </div>
                )}
            </Modal.Body>
        </Modal>
    );
};

export default InviteClientModal;
