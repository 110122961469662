import React, { useState, useEffect, useContext } from 'react';
import { Form, Button, InputGroup, Spinner } from 'react-bootstrap';
import { FaCheckCircle, FaEdit, FaEye, FaEyeSlash } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'js-cookie';
import { url } from '../../utils/networkconfig';
import { ProfileContext } from '../../context/ProfileContext';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCopy } from '@fortawesome/free-solid-svg-icons';



const AdminSettings = () => {
    const [counts, setCounts] = useState({});
    const [EmaiSending, setEmailSending] = useState(false);
    const [error, setError] = useState(null);

    const [emails, setEmails] = useState([]);
    const [emailInput, setEmailInput] = useState('');
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');

    const [referralSummary, setReferralSummary] = useState({});
    const [totalAcceptedInvitations, setTotalAcceptedInvitations] = useState(0);
    const [walletBalance, setWalletBalance] = useState(0.0);
    const [link, setReferralLink] = useState('https://pinnacle-solution.vercel.app/');

    const [copyStatus, setCopyStatus] = useState('Copy'); // Button text state
    const [copied, setCopied] = useState(false); // State to manage if copied
    
    const user = Cookies.get('admin') ? JSON.parse(Cookies.get('admin')) : null;
    const user_id = user ? user.user_id : null;
    

    // Copy link function
    const copyToClipboard = () => {
        navigator.clipboard.writeText(link); // Copy the link to clipboard
        setCopied(true); // Set copied state to true
        setCopyStatus('Copied!'); // Change the button text to "Copied!"

        // Reset the button text after 3 seconds
        setTimeout(() => {
            setCopied(false); // Reset copied state
            setCopyStatus('Copy'); // Reset button text
        }, 3000);
    };

    const handleEmailInput = (e) => {
        if (e.key === 'Enter' || e.key === ' ') {
            const email = e.target.value.trim();
            if (validateEmail(email) && !emails.includes(email)) {
                setEmails([...emails, email]);
                setEmailInput('');
            }
            else {
                toast.error('Please enter a valid email address');
            }
        }
    };

    const handleEmailInput_2 = (e) => {
        // Extracting email value and trimming whitespace
        const email = emailInput.trim();

        // Check if the email is valid
        if (!validateEmail(email)) {
            toast.error('Please enter a valid email address');
            return;
        }

        // Check if the email already exists in the list
        if (emails.includes(email)) {
            toast.error('Email already added');
            return;
        }

        // Add the valid email to the list and reset the input
        setEmails([...emails, email]);
        setEmailInput('');
    };


    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    };

    const handleEmailDelete = (email) => {
        setEmails(emails.filter(e => e !== email));
    };

    // Function to handle the API request
    const handleSendInvite = async () => {
        if (emails.length === 0) {
            toast.error('Please add at least one email address.');
            return;
        }

        setEmailSending(true);  // Start loading

        try {
            // Make API request
            const response = await axios.post(`${url}/api/send-admin-referral-invites/`, {
                email_list: emails,
                adminName: user?.admin_name
            });

            // Success
            toast.success('Invitations sent successfully!');
            setEmails([]);  // Clear the email list after success
        } catch (error) {
            // Handle error
            toast.error('Failed to send invitations. Please try again.');
            console.error(error);
        } finally {
            setEmailSending(false);  // End loading
        }
    };



    const { setProfileUrl, profilePic } = useContext(ProfileContext)

    const [data, setData] = useState({
        fullName: '',
        email: '',
        password: '',
        currentPassword: '',
        imageUrl: profilePic, // Default image URL
    });

    const [editMode, setEditMode] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [validation, setValidation] = useState({});
    const [errors, setErrors] = useState({});

    // Fetch user data
    const fetchData = async () => {
        try {
            const response = await fetch(`${url}/api/admin/${user_id}/profile/`);
            const responseData = await response.json();

            console.log("Admin: ", responseData);

            if (response.ok) {
                // Setting data based on API response
                setData({
                    fullName: responseData.admin_name,
                    email: responseData.email,
                    password: '', // Assuming password is not included in the response
                    imageUrl: responseData.profile_picture || profilePic,
                });
                setProfileUrl(responseData.profile_picture || profilePic)

            } else {
                toast.error('Failed to load user data');
            }
        } catch (error) {
            toast.error('An error occurred while fetching data');
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    // Handle input changes
    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === 'fullName') {
            setErrors({
                ...errors,
                fullName: value.trim().length === 0 ? 'Full name must have at least 1 character.' : '',
            });
        }

        if (name === 'phoneNo') {
            setErrors({
                ...errors,
                phoneNo: !/^\d{11}$/.test(value) ? 'Phone number must have exactly 11 digits.' : '',
            });
        }

        setData({ ...data, [name]: value });
    };

    // Handle image upload
    const handleImageUpload = async (file) => {
        if (file) {
            setLoading(true);

            const formData = new FormData();
            formData.append('profile_picture', file);

            try {
                const response = await fetch(`${url}/api/user/${user_id}/upload-image/`, {
                    method: 'POST',
                    body: formData,
                    headers: {
                        'Authorization': `Bearer ${Cookies.get('token')}`, // Assuming you're using token-based auth
                    },
                });

                const responseData = await response.json();

                if (response.ok) {
                    setData({ ...data, imageUrl: responseData.image_url });
                    setProfileUrl(responseData.image_url || profilePic)
                    toast.success('Image uploaded successfully!');
                } else {
                    toast.error('Failed to upload image');
                }
            } catch (error) {
                toast.error('An error occurred while uploading the image');
            } finally {
                setLoading(false);
            }
        }
    };

    // Trigger image upload dialog
    const handleImageClick = () => {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = 'image/*';
        input.style.display = 'none';

        input.onchange = (e) => {
            const file = e.target.files[0];
            handleImageUpload(file);
        };

        document.body.appendChild(input);
        input.click();
        document.body.removeChild(input);
    };

    // Handle password changes and validate
    const handlePasswordChange = (e) => {
        const value = e.target.value;
        setData({ ...data, password: value });

        setValidation({
            hasLowercase: /[a-z]/.test(value),
            hasUppercase: /[A-Z]/.test(value),
            hasNumber: /\d/.test(value),
            hasSpecialChar: /[!@#$%^&*]/.test(value),
            isMinLength: value.length >= 8,
        });

        setErrors({
            ...errors,
            password: value.length === 0 ? 'Password is required.' : '',
        });
    };

    const handleCurrentPasswordChange = (e) => {
        const value = e.target.value;
        setData({ ...data, currentPassword: value });

        setErrors({
            ...errors,
            currentPassword: value.length === 0 ? 'Current password is required.' : '',
        });
    };

    const handleSave = async () => {
        const isFullNameValid = data.fullName.trim()?.length > 0;
    
        // Check if currentPassword or password is provided
        const isPasswordProvided = data.password?.length > 0;
        const isCurrentPasswordProvided = data.currentPassword?.length > 0;
    
        // Ensure both fields are either filled or empty
        const arePasswordFieldsValid =
            (isPasswordProvided && isCurrentPasswordProvided) || 
            (!isPasswordProvided && !isCurrentPasswordProvided);
    
        // Validate password only if it is provided
        const isPasswordValid = isPasswordProvided
            ? validation.hasLowercase &&
              validation.hasUppercase &&
              validation.hasNumber &&
              validation.hasSpecialChar &&
              validation.isMinLength
            : true;
    
        // If any validation fails, set errors and return
        if (!isFullNameValid || !arePasswordFieldsValid || !isPasswordValid) {
            setErrors({
                fullName: isFullNameValid ? '' : 'Full name must have at least 1 character.',
                currentPassword: arePasswordFieldsValid
                    ? ''
                    : 'Both current password and new password must be filled.',
                password: isPasswordValid || !isPasswordProvided
                    ? ''
                    : 'Password must meet the required criteria.',
            });
    
            toast.error('Please fix the validation errors before saving.');
            return;
        }
    
        setLoading(true);
    
        // Prepare the data to be sent to the API
        const updateData = {
            admin_name: data.fullName,
        };
    
        // Include password and old_password only if they are provided
        if (isPasswordProvided && isCurrentPasswordProvided) {
            updateData.password = data.password;
            updateData.old_password = data.currentPassword;
        }
    
        try {
            const response = await fetch(`${url}/api/admin/${user_id}/profile/update/`, {
                method: 'PUT',
                body: JSON.stringify(updateData),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${Cookies.get('token')}`,
                },
            });
    
            const responseData = await response.json();
    
            if (response.ok) {
                setEditMode(false);
                toast.success('Profile updated successfully!');
                fetchData(); // Refresh the user data after updating
                setData({ ...data, currentPassword: '', password: '' }); // Clear password fields
            } else {
                toast.error(responseData.error || 'Failed to update profile');
            }
        } catch (error) {
            toast.error('An error occurred while saving data');
        } finally {
            setLoading(false);
        }
    };


    return (
        <div className="p-3">
            <ToastContainer />
            <div className="bg-[#F8F8F8] p-4 rounded-md shadow-md mb-6">
                <h2 className="text-3xl font-bold text-[#04394F]">Settings</h2>
                <p className="Barlow">Manage Your Profile</p>
            </div>

            <div className="border rounded-lg shadow-md p-4">
                <div className="flex justify-between items-center mb-4">
                    <div className="text-center">
                        <div className="relative inline-block">
                            <img
                                src={data.imageUrl}
                                alt="Profile"
                                className="rounded-full w-24 h-24 mb-3 object-cover mx-auto"
                            />
                            <FaEdit
                                className="absolute top-0 -right-5 text-yellow-500 cursor-pointer"
                                size={20}
                                onClick={handleImageClick}
                            />
                        </div>
                        <p className="font-medium">{data.fullName}</p>
                        <p className="text-gray-500">{data.email}</p>
                    </div>
                    <Button
                        variant="warning"
                        className='!text-white'
                        onClick={() => setEditMode(!editMode)}
                        disabled={loading}
                    >
                        {editMode ? 'Cancel' : 'Edit'}
                    </Button>
                </div>

                <Form>
                    <Form.Group controlId="fullName" className="mb-3">
                        <Form.Label>Full Name</Form.Label>
                        <Form.Control
                            type="text"
                            name="fullName"
                            placeholder="Enter full name"
                            value={data.fullName}
                            onChange={handleChange}
                            disabled={!editMode}
                            isInvalid={!!errors.fullName}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.fullName}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="currentPassword" className="mb-3">
                        <Form.Label>Enter Current Password</Form.Label>
                        <Form.Control
                            type="password"
                            name="currentPassword"
                            placeholder="Enter current password"
                            value={data.currentPassword}
                            onChange={handleCurrentPasswordChange}
                            disabled={!editMode}
                            isInvalid={!!errors.currentPassword}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.currentPassword}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="password" className="mb-3">
                        <Form.Label>Set New Password</Form.Label>
                        <InputGroup>
                            <Form.Control
                                type={showPassword ? 'text' : 'password'}
                                name="password"
                                placeholder="Enter password"
                                value={data.password}
                                onChange={handlePasswordChange}
                                disabled={!editMode}
                                isInvalid={!!errors.password}
                                className='!rounded-md'
                            />
                            <InputGroup.Text
                                onClick={() => setShowPassword(!showPassword)}
                                className="cursor-pointer absolute !bg-transparent !border-0 right-0 top-2 z-50"
                            >
                                {showPassword ? <FaEyeSlash /> : <FaEye />}
                            </InputGroup.Text>
                        </InputGroup>
                        <Form.Control.Feedback type="invalid">
                            {errors.password}
                        </Form.Control.Feedback>
                        <div className="mt-2 text-xs">
                            <div className="flex gap-[9%]">
                                <div className="flex items-center space-x-2">
                                    <FaCheckCircle
                                        className={`mb-3 ${validation.hasLowercase ? 'text-green-600' : 'text-gray-500'
                                            }`}
                                    />
                                    <p
                                        className={
                                            validation.hasLowercase ? 'text-green-600' : 'text-gray-500'
                                        }
                                    >
                                        One lowercase character
                                    </p>
                                </div>

                                <div className="flex items-center space-x-2">
                                    <FaCheckCircle
                                        className={`mb-3 ${validation.hasNumber ? 'text-green-600' : 'text-gray-500'
                                            }`}
                                    />
                                    <p
                                        className={
                                            validation.hasNumber ? 'text-green-600' : 'text-gray-500'
                                        }
                                    >
                                        One number
                                    </p>
                                </div>

                                <div className="flex items-center space-x-2">
                                    <FaCheckCircle
                                        className={`mb-3 ${validation.hasUppercase ? 'text-green-600' : 'text-gray-500'
                                            }`}
                                    />
                                    <p
                                        className={
                                            validation.hasUppercase ? 'text-green-600' : 'text-gray-500'
                                        }
                                    >
                                        One uppercase character
                                    </p>
                                </div>
                            </div>

                            <div className="flex gap-[12.5%]">
                                <div className="flex items-center space-x-2">
                                    <FaCheckCircle
                                        className={`mb-3 ${validation.hasSpecialChar ? 'text-green-600' : 'text-gray-500'
                                            }`}
                                    />
                                    <p
                                        className={
                                            validation.hasSpecialChar
                                                ? 'text-green-600'
                                                : 'text-gray-500'
                                        }
                                    >
                                        One special character
                                    </p>
                                </div>
                                <div className="flex items-center space-x-2">
                                    <FaCheckCircle
                                        className={`mb-3 ${validation.isMinLength ? 'text-green-600' : 'text-gray-500'
                                            }`}
                                    />
                                    <p
                                        className={
                                            validation.isMinLength
                                                ? 'text-green-600'
                                                : 'text-gray-500'
                                        }
                                    >
                                        8 characters minimum
                                    </p>
                                </div>
                            </div>
                        </div>
                    </Form.Group>
                </Form>

                {editMode && (
                    <div className="text-right mt-4">
                        <Button
                            variant="success"
                            className='!text-white'
                            onClick={handleSave}
                            disabled={loading}
                        >
                            Save
                        </Button>
                    </div>
                )}
            </div>

            <div className="p-3 mt-4 bg-white rounded border-1 border-solid border-[rgba(0, 0, 0, 0.25)]">
                <h2 className="text-2xl font-bold mb-4">New Client Invite</h2>
                <div className="mb-4">
                    <span className="text-[#04394F]">Through Email </span>
                    <div className="flex flex-wrap mt-2 border border-solid border-[#04394F] p-6">
                        {emails.map((email, index) => (
                            <span key={index} className="bg-[#D9E1E5] text-[#04394F] px-2 py-1 rounded-sm flex items-center mr-2 font-semibold">
                                {email}
                                <button onClick={() => handleEmailDelete(email)} className="ml-2 text-black">✖</button>
                            </span>
                        ))}
                        <input
                            type="text"
                            value={emailInput}
                            onChange={(e) => setEmailInput(e.target.value)}
                            onKeyDown={handleEmailInput}
                            className="rounded flex-1 !outline-none !ring-0"
                        />
                    </div>
                    <div className="mt-3 flex justify-between align-middle items-center">
                        <span className="text-black">Invite <span className='bg-[#D9E1E5] text-[#04394F] font-semibold p-2 rounded-md'>{emails.length} new clients</span> to this retrospective</span>
                        <div className='flex gap-2'>
                            <Button
                                onClick={handleSendInvite}
                                className={`bg-gold text-white ${emails.length === 0 || EmaiSending ? 'opacity-50 cursor-not-allowed' : ''}`}
                                disabled={emails.length === 0 || EmaiSending}
                            >
                                {EmaiSending ? (
                                    <div className="flex items-center">
                                        <Spinner></Spinner>
                                        <span className="ml-2">Sending...</span>
                                    </div>
                                ) : (
                                    'Send Invite'
                                )}
                            </Button>
                            <Button
                                onClick={handleEmailInput_2}
                                disabled={emailInput.length < 5}
                            >
                                Add Email
                            </Button>
                        </div>
                    </div>
                    <div className='rounded-md border-t-[1px] mt-4 pt-4 border-t-[rgba(0, 0, 0, 0.4)]'>
                        <p className='text-[#04394F] text-sm Barlow'>Or, Copy the link and sent it to new client</p>
                        <div className="flex gap-4">
                            {/* Box containing the link */}
                            <div className="border rounded-md w-full pt-[1.5%] pl-[1.5%]">
                                <p className="text-sm text-gray-800">{link}</p>
                            </div>

                            {/* Copy button */}
                            <button
                                onClick={copyToClipboard}
                                className="flex items-center text-sm font-medium px-4 py-2 border-2 border-solid border-[#04394F] bg-white text-[#04394F] rounded-md hover:border-2 hover:rounded-md transition duration-200"
                            >
                                {/* Dynamic icon based on whether the link is copied */}
                                <FontAwesomeIcon icon={copied ? faCheck : faCopy} className="h-5 w-5 mr-2" />
                                {copyStatus}
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default AdminSettings;
