import React from 'react';
import { Nav } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom'; // Importing useLocation
import '../../styles/hamburgerMenu.css'; // Contains overlay and mobile styling
import pinnacleLogo from "../../assets/Pinnacle-Logo-Blue 1.png";
import { RiDashboardLine, RiMailSendLine } from 'react-icons/ri';
import { AiOutlineFileText } from 'react-icons/ai';
import { FaCog, FaCrown, FaUserFriends } from 'react-icons/fa';
import { BiLogOutCircle } from 'react-icons/bi';
import Cookies from 'js-cookie';

const AdminSidebar = ({ show, toggleSidebar }) => {
    const location = useLocation(); // Get the current URL
    const navigate = useNavigate();

    const user = Cookies.get('admin') ? JSON.parse(Cookies.get('admin')) : null;

    // Function to check if the link is active
    const isActive = (href) => location.pathname === href;

    const handleLogout = () => {
        // Remove the authentication cookie (change 'authToken' to your cookie name)
        Cookies.remove('admin');

        // Navigate to the login page
        navigate('/admin');
    };

    return (
        <>
            {/* Mobile Sidebar - Overlay Mode */}
            <div className={`sidebar-mobile ${show ? 'show' : ''}`}>
                <div className="sidebar-content bg-[#FFFFFF] !text-[#04394F] h-full">
                    <Nav className="flex-column">
                        <Nav.Link
                            href="#"
                            className={`${isActive('/') ? '!bg-[#B38D2E] text-white !font-semibold' : '!text-[#04394F]'} rounded-md hover:bg-[#B38D2E]  hover:rounded-md hover:!text-white`}
                        >
                            <i className="fas fa-tachometer-alt"></i> Dashboard
                        </Nav.Link>
                        <Nav.Link
                            href="/admin/dashboard/myapplications"
                            className={`${isActive('/admin/dashboard/myapplications' || '/admin/dashboard/application-details/:application_id') ? '!bg-[#B38D2E] text-white !font-semibold' : '!text-[#04394F]'} rounded-md hover:bg-[#B38D2E]  hover:rounded-md hover:!text-white`}
                        >
                            <i className="fas fa-file-alt"></i> Applications
                        </Nav.Link>
                        <Nav.Link
                            href="/admin/dashboard/myclients"
                            className={`${isActive('/admin/dashboard/myclients') ? '!bg-[#B38D2E] text-white !font-semibold' : '!text-[#04394F]'} rounded-md hover:bg-[#B38D2E]  hover:rounded-md hover:!text-white`}
                        >
                            <i className="fas fa-file-alt"></i> Clients
                        </Nav.Link>

                        <Nav.Link
                            href="/admin/dashboard/clientInvites"
                            className={`${isActive('/admin/dashboard/clientInvites') ? '!bg-[#B38D2E] text-white !font-semibold' : '!text-[#04394F]'} rounded-md hover:bg-[#B38D2E]  hover:rounded-md hover:!text-white`}
                        >
                            <i className="fas fa-file-alt"></i> Invites
                        </Nav.Link>

                        {user?.is_superAdmin && <Nav.Link
                            onClick={() => navigate('/admin/dashboard/superAdmin')}
                            className={`!flex items-center ${isActive('/admin/dashboard/superAdmin') ? '!bg-[#B38D2E] !text-white !font-semibold' : '!text-[#00B67A] !font-medium'} rounded-md hover:bg-[#B38D2E] hover:rounded-md hover:!text-white flex items-center`}
                        >
                            <FaCrown className="mr-2" />
                            Super Admin
                        </Nav.Link>}

                        <Nav.Link
                            onClick={() => navigate('/admin/dashboard/admin-settings')}
                            className={`!flex items-center ${isActive('/admin/dashboard/admin-settings') ? '!bg-[#B38D2E] text-white !font-semibold' : '!text-[#04394F] !font-medium'} rounded-md hover:bg-[#B38D2E] hover:rounded-md hover:!text-white flex items-center`}
                        >
                            <FaCog className="mr-2" /> Settings
                        </Nav.Link>

                        <Nav.Link
                            href="/admin"
                            className={`${isActive('/logout') ? '!bg-[#B38D2E] text-white !font-semibold' : '!text-[#04394F]'} rounded-md hover:bg-[#B38D2E]  hover:rounded-md hover:!text-white`}
                        >
                            <i className="fas fa-sign-out-alt"></i> Log Out
                        </Nav.Link>
                    </Nav>
                </div>
                {show && <div className="overlay" onClick={toggleSidebar}></div>}
            </div>

            {/* Desktop Sidebar */}
            <div className="hidden md:block bg-[#FFFFFF] min-h-screen !text-[#04394F] px-4 border-r border-solid border-r-[rgba(4, 57, 79, 0.3)]">
                <div className="flex justify-center pt-3">
                    <img src={pinnacleLogo} alt="Pinnacle Logo" />
                </div>
                <div className="mt-[10%] px-[10%]">
                    <div className="bg-[#04394F] h-[1px] bg-opacity-30"></div>
                </div>
                <Nav className="flex-column p-3 space-y-6">
                    <div className="space-y-3">
                        <Nav.Link
                            onClick={() => navigate('/admin/dashboard')}
                            className={`!flex items-center ${isActive('/admin/dashboard') ? '!bg-[#B38D2E] text-white !font-semibold' : '!text-[#04394F] !font-medium'} rounded-md hover:bg-[#B38D2E] hover:rounded-md hover:!text-white flex items-center`}
                        >
                            <RiDashboardLine size={18} style={{ color: '#04394F', marginRight: '8px' }} />
                            Dashboard
                        </Nav.Link>

                        <Nav.Link
                            onClick={() => navigate('/admin/dashboard/myapplications')}

                            className={`!flex items-center ${isActive('/admin/dashboard/myapplications' || '/admin/dashboard/application-details/:application_id/') ? '!bg-[#B38D2E] text-white !font-semibold' : '!text-[#04394F] !font-medium'} rounded-md hover:bg-[#B38D2E] hover:rounded-md hover:!text-white flex items-center`}
                        >
                            <AiOutlineFileText size={18} style={{ color: '#04394F', marginRight: '8px' }} />
                            Applications
                        </Nav.Link>

                        <Nav.Link
                            onClick={() => navigate('/admin/dashboard/myclients')}
                            className={`!flex items-center ${isActive('/admin/dashboard/myclients') ? '!bg-[#B38D2E] text-white !font-semibold' : '!text-[#04394F] !font-medium'} rounded-md hover:bg-[#B38D2E] hover:rounded-md hover:!text-white flex items-center`}
                        >
                            <FaUserFriends size={18} style={{ color: '#04394F', marginRight: '8px' }} />
                            Clients
                        </Nav.Link>

                        {/* <Nav.Link
                            onClick={() => navigate('/admin/dashboard/clientInvites')}
                            className={`!flex items-center ${isActive('/admin/dashboard/clientInvites') ? '!bg-[#B38D2E] text-white !font-semibold' : '!text-[#04394F] !font-medium'} rounded-md hover:bg-[#B38D2E] hover:rounded-md hover:!text-white flex items-center`}
                        >
                            <RiMailSendLine size={18} style={{ color: '#04394F', marginRight: '8px' }} />
                            Invites
                        </Nav.Link> */}

                    </div>
                    <div className="space-y-3">
                        {user?.is_superAdmin && <Nav.Link
                            onClick={() => navigate('/admin/dashboard/superAdmin')}
                            className={`!flex items-center ${isActive('/admin/dashboard/superAdmin') ? '!bg-[#B38D2E] !text-white !font-semibold' : '!text-[#00B67A] !font-medium'} rounded-md hover:bg-[#B38D2E] hover:rounded-md hover:!text-white flex items-center`}
                        >
                            <FaCrown className="mr-2" />
                            Super Admin
                        </Nav.Link>}

                        <Nav.Link
                            onClick={() => navigate('/admin/dashboard/admin-settings')}
                            className={`!flex items-center ${isActive('/admin/dashboard/admin-settings') ? '!bg-[#B38D2E] text-white !font-semibold' : '!text-[#04394F] !font-medium'} rounded-md hover:bg-[#B38D2E] hover:rounded-md hover:!text-white flex items-center`}
                        >
                            <FaCog className="mr-2" /> Settings
                        </Nav.Link>
                        <Nav.Link
                            onClick={handleLogout}
                            className={`!flex items-center ${isActive('/logout') ? '!bg-[#B38D2E] text-white !font-semibold' : '!text-[#04394F] !font-medium'} rounded-md hover:bg-[#B38D2E] hover:rounded-md hover:!text-white flex items-center`}
                        >
                            <BiLogOutCircle size={18} style={{ color: '#04394F', marginRight: '8px' }} />
                            Log Out
                        </Nav.Link>
                    </div>
                </Nav>
            </div>
        </>
    );
};

export default AdminSidebar;
