import React, { useState, useEffect, useContext } from 'react';
import { Menu } from '@headlessui/react';
import { Spinner, Table, Button, Form, Tab, Nav } from 'react-bootstrap';
import { FaEllipsisV } from 'react-icons/fa';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../../styles/AdminTable.css'
import SendMessageModal from '../AdminComponents/AdminSendMessage';
import InviteClientModal from '../AdminComponents/InviteClientModal';
import { useSearch } from '../../context/AdminSearchContext';
import { url } from '../../utils/networkconfig';
import { ProfileContext } from '../../context/ProfileContext';
import AdminEmailLogs from './AdminEmailLogs';

const AdminMyClients = () => {
    const [clients, setClients] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showMessageModal, setShowMessageModal] = useState(false);
    const [modalData, setModalData] = useState({ name: '', email: '', application_id: '', document_id: null });
    const [showModal, setShowModal] = useState(false);
    const { searchQuery, setIsSearchVisible } = useSearch(); // Access search query from global state
    const { userdata } = useContext(ProfileContext)
    const [activeTab, setActiveTab] = useState("clients");



    const navigate = useNavigate();

    // Fetching clients data from the API
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${url}/api/get_clients/`);
                console.log("Clients Data:", response.data.clients);
                setClients(response.data.clients);
                setLoading(false);
            } catch (error) {
                setError('Error fetching clients data');
                setLoading(false);
            }
        };

        fetchData();
        setIsSearchVisible(true)
    }, []);

    // Function to handle application status color
    const getStatusColor = (status) => {
        switch (status.toLowerCase()) {
            case 'submitted':
                return 'bg-gray-100 text-gray-800';
            case 'in_progress':
                return 'bg-yellow-100 text-yellow-800';
            case 'under_review':
                return 'bg-blue-100 text-blue-800';
            case 'approved':
                return 'bg-green-100 text-green-800';
            case 'rejected':
                return 'bg-red-100 text-red-800';
            default:
                return 'bg-gray-100 text-gray-800';
        }
    };

    const filteredClients = clients.filter(client =>
        client.full_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        client.business_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        client.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
        client.phone_no.toLowerCase().includes(searchQuery.toLowerCase())
    );


    // Dropdown options for referral percentage
    const referralOptions = [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50];

    const handleSendReminder = (client) => {
        setModalData({
            name: client.full_name,
            email: client.email,
            application_id: client.application_id,
            document_id: null,  // Not setting document_id as per your requirement
        });
        setShowMessageModal(true);
    };

    if (loading) {
        return (
            <div className="flex justify-center items-center min-h-screen">
                <Spinner animation="border" />
            </div>
        );
    }

    if (error) {
        return (
            <div className="flex justify-center items-center min-h-screen">
                <p>{error}</p>
            </div>
        );
    }

    return (
        <div className="px-6 py-4 mx-auto">
            {/* Welcome Header */}
            <div className="bg-[#F8F8F8] p-4 rounded-md shadow-md mb-6">
                <h2 className="text-3xl font-bold text-[#04394F]">Welcome {userdata.fullName}!</h2>
                <p className="Barlow">
                    Manage all client onboarding activities, track progress, and send new client invitations.
                </p>
            </div>

            <Tab.Container defaultActiveKey="clients" activeKey={activeTab} onSelect={(key) => setActiveTab(key)}>
                {/* Nav Tabs */}
                <Nav variant="tabs">
                    <Nav.Item>
                        <Nav.Link eventKey="clients">Clients</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="emailLogs">Email Logs</Nav.Link>
                    </Nav.Item>
                </Nav>
                <Tab.Content className="mt-4 border p-4 bg-white shadow-md rounded-lg">
                    {/* Clients Tab */}
                    <Tab.Pane eventKey="clients">
                        <div className="container mx-auto mt-8 border-1 border-solid border-[rgba(0, 0, 0, 0.25)]">
                            <div className="rounded-sm">
                                <div className="bg-white p-4 border-b border-gray-200">
                                    <div className="flex justify-between items-center">
                                        <h2 className="text-2xl font-bold text-[#04394F]">Client Overview</h2>
                                        <Button onClick={() => setShowModal(true)} variant="warning">Invite New Client</Button>
                                    </div>
                                    <p className="Barlow">
                                        Get a snapshot of all your client onboarding activities, including pending applications and recent submissions.
                                    </p>
                                </div>

                                {/* Table */}
                                <div className="table-responsive">
                                    {filteredClients.length === 0 ? (
                                        <p className="text-center p-4">No applications are there.</p>
                                    ) : (
                                        <Table hover responsive className="min-w-full auto-height admin-table">
                                            <thead>
                                                <tr>
                                                    <th>Client Name</th>
                                                    <th>Email</th>
                                                    <th>Business Name</th>
                                                    <th>Phone No.</th>
                                                    <th>Application Status</th>
                                                    {/* <th>Referral Percentage</th> */}
                                                    <th>Last Activity</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {filteredClients.map((client, index) => (
                                                    <tr key={index}>
                                                        <td>{client.full_name}</td>
                                                        <td>{client.email}</td>
                                                        <td>{client.business_name}</td>
                                                        <td>{client.phone_no}</td>
                                                        <td>
                                                            <span
                                                                className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getStatusColor(client.application_status)}`}
                                                            >
                                                                {client.application_status.replace('_', ' ').replace(/\b\w/g, char => char.toUpperCase())}
                                                            </span>
                                                        </td>
                                                        {/* Referral Percentage Dropdown */}
                                                        {/* <td>
                                                <select
                                                    defaultValue={client.referral_percentage}
                                                    className="text-xs w-full h-[30px] Barlow text-black p-1 border-1 border-solid border-[#04394F] focus:border-2"
                                                    aria-label="Referral Percentage"
                                                >
                                                    {referralOptions.map((option) => (
                                                        <option key={option} value={option}>
                                                            {option}%
                                                        </option>
                                                    ))}
                                                </select>
                                            </td> */}
                                                        <td>{new Date(client.lastActivity).toLocaleString()}</td>
                                                        <td>
                                                            <Menu as="div" className="relative inline-block text-left">
                                                                <Menu.Button>
                                                                    <FaEllipsisV />
                                                                </Menu.Button>

                                                                <Menu.Items className="origin-top-right absolute right-3 bottom-[-10px] mt-2 w-32 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
                                                                    <div className="py-1 Barlow">
                                                                        <Menu.Item>
                                                                            {({ active }) => (
                                                                                <a
                                                                                    href="#"
                                                                                    onClick={() => navigate(`/admin/dashboard/application-details/${client.application_id}/`)}
                                                                                    className={`block px-4 py-2 !no-underline text-xs ${active ? 'bg-gray-100' : ''}`}
                                                                                >
                                                                                    View Details
                                                                                </a>
                                                                            )}
                                                                        </Menu.Item>
                                                                        <Menu.Item>
                                                                            {({ active }) => (
                                                                                <a
                                                                                    href="#"
                                                                                    className={`block px-4 py-2 text-xs !no-underline ${active ? 'bg-gray-100' : ''}`}
                                                                                    onClick={() => handleSendReminder(client)}
                                                                                >
                                                                                    Send Reminder
                                                                                </a>
                                                                            )}
                                                                        </Menu.Item>
                                                                    </div>
                                                                </Menu.Items>
                                                            </Menu>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    )}
                                </div>
                            </div>
                        </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="emailLogs">
                        <AdminEmailLogs activeTab={activeTab}></AdminEmailLogs>
                    </Tab.Pane>
                </Tab.Content>
            </Tab.Container>


            <SendMessageModal
                show={showMessageModal}
                handleClose={() => setShowMessageModal(false)}
                name={modalData.name}
                email={modalData.email}
                application_id={modalData.application_id}
                document_id={modalData.document_id}  // This will be null as per your requirement
            />
            <InviteClientModal show={showModal} handleClose={() => setShowModal(false)} />

        </div>
    );
};

export default AdminMyClients;
