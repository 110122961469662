// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MainLayout from './components/Layout/mainLayout';
import { BannerProvider } from './context/bannerContext';
import SignupForm from './components/SignUp/signupForm';
import CheckEmail from './components/CheckEmail/Check_Email';
import Login from './components/Login/login';
import { ProgressProvider } from './context/ProgressContext';
import ProcedureLayout from './components/Layout/ProcedureLayout';
import Step1 from './components/Steps/step1';
import Step2 from './components/Steps/step2';
import Step3 from './components/Steps/step3';
import Step4 from './components/Steps/step4';
import Step5 from './components/Steps/step5';
import Step6 from './components/Steps/step6';
import Layout from './components/Layout/DashboardLayout';
import Dashboard from './components/Dashboard/dashboard';
import AdminLayout from './components/Admin/AdminLoginLayout';
import AdminLogin from './components/Admin/AdminLogin';
import AdminDashboardLayout from './components/Admin/AdminDashboardLayout';
import AdminDashboard from './components/Admin/AdminDashboard';
import EmailVerification from './components/EmailVerification/EmailVerification';
import ThankYou from './components/Steps/thankyou';
import AdminMyApplications from './components/Admin/AdminMyApplications';
import ApplicationDetails from './components/Admin/ApplicationDetails';
import AdminMyClients from './components/Admin/AdminMyClients';
import UserMyApplications from './components/Dashboard/UserMyApplications';
import UserApplicationDetails from './components/Dashboard/UserApplicationDetails';
import { DocumentUploadProvider } from './context/DocumentUploadContext';
import ApplicationForm from './components/Dashboard/NewApplication';
import ReferralDashboard from './components/Dashboard/ReferralDashborad';
import { ToastContainer } from 'react-bootstrap';
import "react-toastify/dist/ReactToastify.css";
import { SearchProvider } from './context/AdminSearchContext';
import Settings from './components/Dashboard/Settings';
import { ProfileProvider } from './context/ProfileContext';
import AdminSettings from './components/Admin/AdminSettings';
import SuperAdmin from './components/Admin/SuperAdmin';
import ResetPassword from './components/EmailVerification/UserResetPassword';
import AdminResetPassword from './components/Admin/AdminResetPassword';
import UserForgotPassword from './components/EmailVerification/UserForgotPassword';
import AdminForgotPassword from './components/Admin/AdminForgotPassword';
import AdminEmailLogs from './components/Admin/AdminEmailLogs';



const App = () => {
  return (
    <Router>
      <ProfileProvider>
        <SearchProvider>
          <DocumentUploadProvider>
            <BannerProvider>
              <ProgressProvider>
                <Routes>
                  <Route path="/verify-email/:token" element={<EmailVerification />} />


                  <Route path="/" element={<MainLayout />}>
                    {/* Add more routes here */}
                    <Route index element={<SignupForm />} />
                    <Route path='/checkEmail' element={<CheckEmail />} />
                    <Route path='/login' element={<Login />} />
                    <Route path='/forgot-password' element={<UserForgotPassword />} />
                    <Route path='/reset-password/:token' element={<ResetPassword />} />
                  </Route>

                  <Route path="/steps" element={<ProcedureLayout />}>
                    {/* Add more routes here */}
                    <Route index element={<Step1 />} />
                    <Route path='step2' element={<Step2 />} />
                    <Route path='step3' element={<Step3 />} />
                    <Route path='step4' element={<Step4 />} />
                    <Route path='step5' element={<Step5 />} />
                    <Route path='step6' element={<Step6 />} />
                    <Route path="thankyou" element={<ThankYou />} />
                  </Route>

                  <Route path='/dashboard' element={<Layout />}>
                    <Route index element={<Dashboard />} />
                    <Route path='myapplications' element={<UserMyApplications />} />
                    <Route path="application-details/:application_id" element={<UserApplicationDetails />} />
                    <Route path="NewApplication" element={<ApplicationForm />} />
                    <Route path="ReferralDashboard" element={<ReferralDashboard />} />
                    <Route path='settings' element={<Settings />} />
                  </Route>

                  <Route path='/admin' element={<AdminLayout />}>
                    <Route index element={<AdminLogin />} />
                    <Route path='checkEmail' element={<CheckEmail />} />
                    <Route path='forgot-password' element={<AdminForgotPassword />} />
                    <Route path='reset-password/:token' element={<AdminResetPassword />} />
                  </Route>

                  <Route path='/admin/dashboard' element={<AdminDashboardLayout />}>
                    <Route index element={<AdminDashboard />} />
                    <Route path='myclients' element={<AdminMyClients />} />
                    <Route path='myapplications' element={<AdminMyApplications />} />
                    <Route path="application-details/:application_id" element={<ApplicationDetails />} />
                    <Route path='admin-settings' element={<AdminSettings />} />
                    {/* <Route path='clientInvites' element={<AdminEmailLogs />} /> */}
                    <Route path='superAdmin' element={<SuperAdmin />} />
                  </Route>


                </Routes>
              </ProgressProvider>
            </BannerProvider>
          </DocumentUploadProvider>
        </SearchProvider>
        <ToastContainer />
      </ProfileProvider>
    </Router>
  );
};

export default App;
